import React, { useState } from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import Img from 'gatsby-image';
import { graphql, useStaticQuery } from 'gatsby';

import { BREAKPOINTS, COLORS } from '../../ui/variables';
import Heading3 from '../../ui/Heading3';
import Button from '../../ui/BaseButton';
import ParagraphLarge from '../../ui/ParagraphLarge';

const Container = styled.section`
  background: #f0f0f2;
  margin-top: 100px;

  @media ${BREAKPOINTS.MOBILE} {
    margin-top: 60px;
  }
`;

const Wrapper = styled.div`
  max-width: 1240px;
  padding: 80px 40px;
  margin: auto;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;

  @media ${BREAKPOINTS.MOBILE} {
    flex-direction: column;
    justify-content: center;
    padding: 0;
    max-width: 600px;
  }
`;

const TextContainer = styled.div`
  width: 55%;

  @media ${BREAKPOINTS.MOBILE} {
    width: 100%;
    text-align: center;
    padding: 40px 20px;
    text-align: left;
  }
`;

const ImageContainer = styled.div`
  width: 35%;

  @media ${BREAKPOINTS.MOBILE} {
    width: 69%;
    margin-bottom: -50px;
    margin-top: 40px;
  }
`;

const CTA = styled(Button)`
  padding: 14px 24px;
`;

const BookTrial = () => {
  const data = useStaticQuery(graphql`
    query {
      DogDoctorGraphic: file(
        relativePath: { eq: "graphics/dog-doctor-noses-touching.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 270) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  return (
    <Container>
      <Wrapper>
        <ImageContainer>
          <Img
            css={css`
              margin: auto;

              @media ${BREAKPOINTS.MOBILE} {
                width: 100%;
                order: -1;
              }
            `}
            fluid={data.DogDoctorGraphic.childImageSharp.fluid}
            alt='Doctor Dog Nose Touch Graphic'
          />
        </ImageContainer>
        <TextContainer>
          <Heading3>
          Have upcoming vet care needs?
          </Heading3>
          <ParagraphLarge>
          Head to your local Small Door — and tell them Morris sent you.
          </ParagraphLarge>
          <CTA
            css={css`
              margin-bottom: 16px;

              @media ${BREAKPOINTS.MOBILE} {
                margin-bottom: 20px;
                height: 44px;
              }
            `}
            type='external'
            color={COLORS.ORANGE}
            href={`${process.env.GATSBY_NEW_ONBOARDING_URL}signup`}
          >
            Book Now
          </CTA>
        </TextContainer>
      </Wrapper>
    </Container>
  );
};

export default BookTrial;
