import styled from '@emotion/styled'
import React from 'react'
import { BREAKPOINTS } from '../ui/variables';

const Container = styled.div`
  display: flex;
  
  @media ${BREAKPOINTS.MOBILE} {
    display: block;
  }
`

const Author = styled.div`
  font-family: 'GT Walsheim';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #000000;
  margin-right: 8px;
`;

const Date = styled.div`
  font-family: 'GT Walsheim';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #999999;
`;

interface Props {
  author: string;
  date: string;
};

const AuthorDate = (props: Props) => {

  return (
    <Container>
      <Author>By {props.author}</Author>
      <Date>{props.date}</Date>
    </Container>
  )
}

export default AuthorDate