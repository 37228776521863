import styled from '@emotion/styled';
import React from 'react'
import Heading3 from '../../ui/Heading3'
import { Link } from 'gatsby';
import { BREAKPOINTS, COLORS } from '../../ui/variables';
import { ReactComponent as ArrowRightIco } from "../../images/icons/arrow-right.svg";
import { css } from '@emotion/core';
import ParagraphLarge from '../../ui/ParagraphLarge';

const Container = styled.div`
  margin: 100px 0;

  @media ${BREAKPOINTS.MOBILE} {
    margin: 60px 0;
  }
`;

const LocationLink = styled(Link)`
  font-size: 32px;
  font-weight: 500;
  line-height: 38px;
  display: block;

  @media ${BREAKPOINTS.MOBILE} {
    font-size: 32px;
  }
`;

const LocationContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media ${BREAKPOINTS.MOBILE} {
    display: block;
  }
`;

const InfoContainer = styled.div`
  display: block;
  width: 50%;
  margin-bottom: 10px;

  @media ${BREAKPOINTS.MOBILE} {
    width: 100%;
  }
`;

const locationData = [
  {
    name: "West Village",
    address: "15 7th Avenue, New York, NY 10011",
    url: "/locations/new-york-city/west-village"
  },
  {
    name: "Upper West Side",
    address: "667 Columbus Ave, New York, NY 10025",
    url: "/locations/new-york-city/upper-west-side"
  },
  {
    name: "Upper East Side",
    address: "1231 3rd Avenue, New York, NY 10021",
    url: "/locations/new-york-city/upper-east-side"
  },
  {
    name: "Gramercy",
    address: "380 2nd Avenue, New York, NY 10010",
    url: "/locations/new-york-city/gramercy"
  },
  {
    name: "Williamsburg",
    address: "118 N 4th St, Brooklyn, NY 11249",
    url: "/locations/new-york-city/williamsburg"
  },
  {
    name: "Navy Yard",
    address: "949 First Street, SE, Washington DC, 20003",
    url: "/locations/washington-dc/navy-yard"
  },
  {
    name: "14th Street",
    address: "2110 14th Street, NW, Washington DC, 20009",
    url: "/locations/washington-dc/14th-st"
  },
  {
    name: "Spring Valley",
    address: "4301 49th Street, NW, Washington, DC 20016",
    url: "/locations/washington-dc/spring-valley"
  },
  {
    name: "Bethesda",
    address: "10253 Old Georgetown Rd, Bethesda, MD 20814",
    url: "/locations/washington-dc/bethesda"
  },
  {
    name: "Brookline",
    address: "297 Harvard St, Brookline, MA 02446",
    url: "/locations/boston/brookline"
  },
  {
    name: "Newton Centre",
    address: "718 Beacon St, Newton, MA 02459",
    url: "/locations/boston/newton-centre"
  },
]

const Locations = () => {
  return (
    <Container>
      <Heading3>
        Visit any of our locations
      </Heading3>
      <LocationContainer>
        {locationData.map(location => {
          return (
            <InfoContainer>
              <LocationLink
                css={css`
                  @media ${BREAKPOINTS.MOBILE} {
                    font-size: 20px;
                  }
                `}
                to={location.url}
              >
                {location.name}{" "}
                <ArrowRightIco
                  width="24"
                  css={css`
                fill: ${COLORS.ORANGE};
              `}
                />
              </LocationLink>
              <ParagraphLarge
                css={css`
                @media ${BREAKPOINTS.MOBILE} {
                  font-size: 14px;
                }
              `}
              >
                {location.address}
              </ParagraphLarge>
            </InfoContainer>
          )
        })}
      </LocationContainer>
    </Container>
  )
}

export default Locations