import { css } from '@emotion/core'
import styled from '@emotion/styled';
import React from 'react'
import Heading4 from '../../ui/Heading4'
import ParagraphMedium from '../../ui/ParagraphMedium';
import Img, { FluidObject } from "gatsby-image";
import { BREAKPOINTS } from '../../ui/variables';

const Container = styled.div`
  margin-bottom: 60px;
  height: 280px;
  display: inline-block;
`;

const TextContainer = styled.div`
  width: 48%;
  float: left;

  @media ${BREAKPOINTS.MOBILE} {
    float: none;
    width: 100%;
    margin-top: 332px;
  }
`;

const ImageContainer = styled.div`
  width: 48%;
  float: right;
  text-align: center;

  @media ${BREAKPOINTS.MOBILE} {
    float: none;
    width: 100%;
    margin-top: 40px;
  }
`;

interface Props {
  heading: string;
  text: string;
  img: FluidObject;
}


const Perks = (props: Props) => {
  return (
    <Container>
      <ImageContainer>
        <Img
          css={css`
            float: right;
            object-fit: cover;
            width: 378px;
            height: 252px;
          
            @media ${BREAKPOINTS.MOBILE} {
              width: 100%;
              height: auto;
              margin-bottom: 20px;
            }
          `}
          fluid={props.img}
        />
      </ImageContainer>
      <TextContainer>
        <Heading4>
          {props.heading}
        </Heading4>
        <ParagraphMedium
          css={css`
            line-height: 28px;
          `}
        >
          {props.text}
        </ParagraphMedium>
      </TextContainer>
    </Container>
  )
}

export default Perks