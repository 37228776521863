import styled from '@emotion/styled';
import { graphql } from 'gatsby';
import React from 'react';
import AuthorDate from '../../components/AuthorDate';
import '../../components/layout.css';
import GlobalStyles from '../../components/GlobalStyles';
import Header from '../../components/header/lp/Header';
import Heading1 from '../../ui/Heading1';
import { LandingPageQuery } from '../../../graphql-types';
import Img from 'gatsby-image';
import { css } from '@emotion/core';
import QuoteWithIcon from '../../components/QuoteWithIcon';
import Heading3 from '../../ui/Heading3';
import Perks from '../../components/lp/Perks';
import BookTrial from '../../components/lp/BookTrial';
import Review from '../../components/lp/Review';
import Locations from '../../components/lp/Locations';
import ArticleParagraph from '../../components/article/Paragraph';
import { BREAKPOINTS } from '../../ui/variables';
import Footer from '../../components/lp/Footer';
import StickyFooter from '../../components/lp/StickyFooter';

const NarrowContainer = styled.div`
  width: 820px;
  margin: auto;

  @media ${BREAKPOINTS.MOBILE} {
    width: auto;
    padding: 15px;
  }

  @media ${BREAKPOINTS.TABLET} {
    width: auto;
    padding: 15px;
  }
`;

const StyledParagraph = styled.div`
  margin-top: 0px;

  @media ${BREAKPOINTS.MOBILE} {
    margin-top: 60px;
  }
`;

export const pageQuery = graphql`
  query LandingPage {
    location: file(
      relativePath: { eq: "pages/lp/0_UWSHeaderPhoto.webp" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2738) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    morris: file(
      relativePath: { eq: "pages/lp/Second_Image_Morris_beach_high res.jpeg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2738) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    founders: file(
      relativePath: { eq: "pages/lp/josh_and_florent_highres.jpeg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2738) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    perk1: file(relativePath: { eq: "pages/lp/1_GoldStandard.webp" }) {
      childImageSharp {
        fluid(maxWidth: 2738) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    perk2: file(relativePath: { eq: "pages/lp/2_ConvenientPriorityAppt.webp" }) {
      childImageSharp {
        fluid(maxWidth: 2738) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    perk3: file(relativePath: { eq: "pages/lp/3_DedicatedTime.webp" }) {
      childImageSharp {
        fluid(maxWidth: 2738) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    perk4: file(
      relativePath: { eq: "pages/lp/5_Membership.webp" }
    ) {
      childImageSharp {
        fluid(maxWidth: 2738) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    perk5: file(
      relativePath: {
        eq: "pages/lp/4_Nooks.webp"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 2738) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    reviewer1: file(relativePath: { eq: "pages/lp/reviewer1.jpeg" }) {
      childImageSharp {
        fixed(width: 42) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    reviewer2: file(relativePath: { eq: "pages/lp/reviewer2.jpeg" }) {
      childImageSharp {
        fixed(width: 42) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    reviewer3: file(relativePath: { eq: "pages/lp/reviewer3.jpeg" }) {
      childImageSharp {
        fixed(width: 42) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    google: file(relativePath: { eq: "pages/lp/google-reviews.png" }) {
      childImageSharp {
        fixed(width: 130) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    yelp: file(relativePath: { eq: "pages/lp/yelp.png" }) {
      childImageSharp {
        fixed(width: 130) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`;

interface Props {
  data: LandingPageQuery;
}
//new version
const FoundingStory1 = ({ data }: Props) => {
  return (
    <>
      <GlobalStyles />
      <Header
        sticky={false}
        cta={{
          href: `${process.env.GATSBY_NEW_ONBOARDING_URL}signup`,
          text: 'Book Now',
        }}
      />
      <>
        <NarrowContainer>
          <Heading1
            css={css`
              margin-top: 60px;

              @media ${BREAKPOINTS.MOBILE} {
                margin-top: 40px;
              }
            `}
          >
            Why We Started Small Door
          </Heading1>
          <AuthorDate
            author='Josh Guttman, Small Door Co-Founder'
            date='July 11, 2024'
          />
          <Img
            css={css`
              width: 820px;
              height: 456px;
              margin-top: 40px;
              margin-bottom: 40px;

              @media ${BREAKPOINTS.MOBILE} {
                width: 100%;
                height: auto;
              }

              @media ${BREAKPOINTS.TABLET} {
                width: 100%;
                height: auto;
              }
            `}
            fluid={data.location.childImageSharp.fluid}
          />
          <ArticleParagraph>
            Finding great veterinary care today is really difficult. I learned
            this the hard way five years ago when my boxer, Morris, got sick. We
            saw our local vet, but she couldn’t find anything wrong. Over the
            next year, we visited eight veterinary clinics, racking up thousands
            of dollars in bills as Morris withstood one test — and cheerless
            waiting room — after another. Same-day appointments were rarely
            available and medical records were closely guarded.
          </ArticleParagraph>
          <QuoteWithIcon
            quote='I felt rushed during appointments and was never quite sure what I was paying for.
            What’s more, I could never speak directly to a doctor outside of appointments.'
          />
          <ArticleParagraph>
            Thankfully, Morris’ story had a happy ending. He was eventually
            diagnosed with a condition common to his breed, and with treatment,
            returned to his happy life playing tug-of-war and bodysurfing ocean
            waves.
          </ArticleParagraph>
          <Img
            css={css`
              width: 820px;
              height: 456px;
              margin-top: 48px;
              margin-bottom: 60px;

              @media ${BREAKPOINTS.MOBILE} {
                width: 100%;
                height: auto;
                margin-top: 40px;
                margin-bottom: 40px;
              }

              @media ${BREAKPOINTS.TABLET} {
                width: 100%;
                height: auto;
                margin-top: 40px;
                margin-bottom: 40px;
              }
            `}
            fluid={data.morris.childImageSharp.fluid}
          />
          <ArticleParagraph>
            Small Door was born out of the hope that Morris’ experience could
            become the exception and no longer the norm. I teamed up with my
            friend Florent and our Head of Veterinary Medicine, Dr. Jamie
            Richardson, and we created Small Door with a simple goal: to provide
            a better veterinary experience for both pets and pet parents. We
            started with one practice in the West Village and perfected our
            reimagined veterinary experience for over a year before we felt
            ready to bring it to the world.
          </ArticleParagraph>
          <Img
            css={css`
              width: 820px;
              height: 456px;

              @media ${BREAKPOINTS.MOBILE} {
                width: 100%;
                height: auto;
              }

              @media ${BREAKPOINTS.TABLET} {
                width: 100%;
                height: auto;
              }
            `}
            fluid={data.founders.childImageSharp.fluid}
          />
          <Heading3
            css={css`
              font-size: 31px;
              margin-top: 80px;
              margin-bottom: 80px;

              @media ${BREAKPOINTS.MOBILE} {
                margin-top: 60px;
                margin-bottom: 0px;
              }
            `}
          >
            We designed Small Door to deliver better care for pets and a better experience for pet parents. Here’s how we do it.
          </Heading3>
          <Perks
            text='Our vets are best in class, and we’re proud to be among just 15% of veterinary hospitals in the U.S. to be accredited by the American Animal Hospital Association. Our onsite lab, diagnostics and radiology, and dental and surgical suites mean we can offer a full range of pet health services in one place.'
            heading='Gold-standard medical care'
            img={data.perk1.childImageSharp.fluid}
          />
          <Perks
            text='To make sure you can always get care when you need it, we limit our daily appointment numbers. (Oh, and those appointments? They actually start on time.) You can book and manage appointments, submit prescription requests, and download vaccine certificates online or in our app — no waiting on hold required.'
            heading='Convenient, priority appointments'
            img={data.perk2.childImageSharp.fluid}
          />
          <Perks
            text='We don’t double book, and our appointments are longer than you might be used to. You’ll never feel rushed — and you’ll always have your vet’s undivided attention. They’ll take the time to understand what’s really going on with your pet, then lay out all the treatment options so you can decide on the best course of action together.'
            heading='Dedicated time with exceptional doctors'
            img={data.perk3.childImageSharp.fluid}
          />
          <Perks
            text='Who says the vet has to make pets anxious? Our teams are trained in fear-free techniques, and our spaces are designed to be stress-free. Each of our state-of-the-art practices features private spaces, easy-grip surfaces, soft furnishings, natural lighting, and anxiety-reducing scents.'
            heading='Practices that make pets feel at home'
            img={data.perk5.childImageSharp.fluid}
          />
          <Perks
            text='You don’t have to be a member to bring your pet to Small Door, but it’s a great option for younger (or older) pets who need to see the vet often. For just $169/year, you get 24/7 telehealth for peace of mind, one free annual exam to help keep your pet healthy, 20% off additional exam fees, and same or next-day appointments.'
            heading='Membership for frequent vet visitors'
            img={data.perk4.childImageSharp.fluid}
          />
          <StyledParagraph>
            <ArticleParagraph>
            We’ve come a long way since that first practice. We now have 11 locations across New York City, Boston, and Washington DC, and we’ve provided first-class veterinary care to tens of thousands of pets (and their adoring pet parents). And we’re just getting started.
            </ArticleParagraph>
          </StyledParagraph>
        </NarrowContainer>
        <BookTrial />
        <NarrowContainer>
          <Heading3
            css={css`
              font-size: 31px;
              margin-top: 100px;

              @media ${BREAKPOINTS.MOBILE} {
                margin-top: 60px;
              }
            `}
          >
            We are proud to be one of the highest rated veterinary practices on
            Google and Yelp, with over 1,000+ five-star reviews.
          </Heading3>
          <Review
            review='I absolutely love Small Door! I have messaged with vets several times now about things I thought were a big deal,
            like Frankie having a cough or experiencing mild stomach issues, that ended up having easy at-home treatment and saved me a vet visit.'
            name='Nikki B.'
            img={data.reviewer1.childImageSharp.fixed}
            logo={data.yelp.childImageSharp.fixed}
          />
          <Review
            review='Small Door is an amazing service! The doctors are caring and take their time to assess all issues with your pet. 
            I left my first appointment with them more educated on my dog’s issues than any other vet apt!'
            name='Faith L.'
            img={data.reviewer2.childImageSharp.fixed}
            logo={data.google.childImageSharp.fixed}
          />
          <Review
            review='Being a first time dog mom, I’m extremely nervous when it comes to health concerns about my pup. 
            Their 24/7 chat eases my anxiety 101%! Even if I send a message over at 4AM someone is always available to help out.'
            name='Johnelle P.'
            img={data.reviewer3.childImageSharp.fixed}
            logo={data.google.childImageSharp.fixed}
          />
          <Locations />
          </NarrowContainer>
        <Footer />
        <StickyFooter />
      </>
    </>
  );
};

export default FoundingStory1;
