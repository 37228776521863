import { css } from '@emotion/core';
import styled from '@emotion/styled';
import React from 'react';
import { ReactComponent as OrangeQuoteIcon } from '../images/icons/orange-quote.svg';
import { BREAKPOINTS, COLORS } from '../ui/variables';
import ArticleQuote from './article/Quote';

const Container = styled.div`
  display: flex;

  @media ${BREAKPOINTS.MOBILE} {
    display: block;
  }
`;

const Quote = styled.div`
  margin-left: 40px;
  margin-top: 20px;
  margin-bottom: 40px;
  font-family: 'GT Walsheim';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
  letter-spacing: -0.304644px;
  color: ${COLORS.ORANGE};

  @media ${BREAKPOINTS.MOBILE} {
    max-width: 517px;
    margin-left: 0px;
  }
`;

interface Props {
  quote: string;
}

const QuoteWithIcon = ({ quote }: Props) => {
  return (
    <Container>
      <div>
        <OrangeQuoteIcon
          css={css`
            overflow: inherit !important;
            width: 100%;

            @media ${BREAKPOINTS.MOBILE} {
              width: 10%;
            }
          `}
          viewBox='0 0 47 33'
          width='100%'
        />
      </div>
      <Quote>{quote}</Quote>
    </Container>
  );
};

export default QuoteWithIcon;
