import styled from '@emotion/styled'
import { FixedObject } from 'gatsby-image'
import React from 'react'
import ParagraphLarge from '../../ui/ParagraphLarge'
import ReviewStarIco from "../../images/icons/review-star-orange.svg";
import Img from "gatsby-image";
import { css } from '@emotion/core';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
`;

const LogoContainer = styled.div`
  float: right;
`;

const AvatarContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  float: left;
`;

const StyledImg = styled.div`
  margin-right: 10px;
`

const Star = styled.span`
  display: inline-block;
  background: url(${ReviewStarIco});
  width: 20px;
  height: 20px;
`;

interface Props {
  name: string;
  review: string;
  img: FixedObject;
  logo: FixedObject;
}

const Review = (props: Props) => {
  return (
    <>
      <ParagraphLarge>
        {props.review}
      </ParagraphLarge>
      <Container>
        <AvatarContainer>
          <StyledImg>
            <Img
              css={css`
                border-radius: 50%;
              `} 
              fixed={props.img} />
          </StyledImg>
          <span>
            {props.name} <br />
            {new Array(5).fill(<Star />)}
          </span>
        </AvatarContainer>
        <LogoContainer>
          <Img fixed={props.logo} />
        </LogoContainer>
      </Container>
    </>
  )
}

export default Review