import React from "react";
import styled from "@emotion/styled";

import ParagraphLarge from "../../ui/ParagraphLarge";
import { BREAKPOINTS } from "../../ui/variables";

const Container = styled(ParagraphLarge)`
  max-width: 810px;
  margin-bottom: 30px;
  width: 100%;

  @media ${BREAKPOINTS.TABLET} {
    max-width: 615px;
  }

  @media ${BREAKPOINTS.MOBILE} {
    max-width: 517px;
    /* margin-bottom: 60px; */
  }
`;

const ArticleParagraph = ({ children }: { children: React.ReactNode }) => (
  <Container>{children}</Container>
);

export default ArticleParagraph;
