import { css } from '@emotion/core';
import styled from '@emotion/styled';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import Button from '../../ui/BaseButton';
import ParagraphLarge from '../../ui/ParagraphLarge';
import { BREAKPOINTS, COLORS } from '../../ui/variables';

const Container = styled.div<{ sticky: boolean }>`
  box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.08);
  padding: 20px 25%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: ${props => (props.sticky ? 'sticky' : 'inherit')};
  bottom: 0px;
  background-color: white;

  @media ${BREAKPOINTS.MOBILE} {
    padding: 20px 20px !important;
  }

  @media ${BREAKPOINTS.TABLET} {
    padding: 20px 0;
  }
`;

const TextContainer = styled.div`
  float: left;

  @media ${BREAKPOINTS.MOBILE} {
    width: 65%;
  }
`;

const CTA = styled(Button)`
  padding: 14px 24px;
`;

function useWindowSize() {
  const [size, setSize] = useState(0);
  useLayoutEffect(() => {
    function updateSize() {
      setSize(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

const StickyFooter = () => {
  const width = useWindowSize();
  const [buttonText, setButtonText] = useState('Book Now');

  const [text, setText] = useState('Ready for better veterinary care?');

  const [sticky, setSticky] = useState(false);

  useEffect(() => {
    if (width <= 768) {
      setButtonText('Book Now');
      setText('Get started today');
    } else {
      setButtonText('Book Now');
      setText('Ready for better veterinary care?');
    }
  }, [width]);

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      return setSticky(true);
    }
    setSticky(false);
  };

  return (
    <Container sticky={sticky}>
      <TextContainer>
        <ParagraphLarge
          css={css`
            margin-bottom: 0px;
            font-weight: 600;

            @media ${BREAKPOINTS.MOBILE} {
              font-size: 14px;
            }
          `}
        >
          {text}
        </ParagraphLarge>
      </TextContainer>
      <CTA
        css={css`
          @media ${BREAKPOINTS.MOBILE} {
            height: 44px;
            white-space: nowrap;
            font-size: 14px;
          }
        `}
        type='external'
        color={COLORS.ORANGE}
        href={`${process.env.GATSBY_NEW_ONBOARDING_URL}signup`}
      >
        {buttonText}
      </CTA>
    </Container>
  );
};

export default StickyFooter;
